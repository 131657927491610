import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';

import CardListBuilding from '../Components/Card/cardListBuilding';
import ButtonPluss from '../Components/Button/buttonPluss';
import NewBuildingpModal from '../Components/Modal/newBuildingModal';
import Dashboard from '../Containers/dashboard';

import GetBuilding from '../Helpers/Gettters/getBuilding';

const useStyles = makeStyles((theme) => ({
    root: {
      height: '100vh',
      textAlign: 'center'
    },
      content: {
        flexGrow: 1,
        overflow: 'auto',
      },
      container: {

        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
      },
}));

 export default function Building() {
    const classes = useStyles();

    const [modalAdd, setModalAdd] = useState(false);
    const [building, setBuilding] = useState([]);
    const [loading, setLoading] = useState(true);

    function onClickPluss() {
      setModalAdd(true);
    }

    function OnCloseModal(){
      setModalAdd(false);
    }

    function OnClickCancellBuilding() {
      setModalAdd(false);
    }

    const getAllBuilding = async () => {
      const list = await GetBuilding();
      setBuilding(list);
      console.log(list)
      setLoading(false)
    }

    const convertDate = (date) => {
      let parseDate = (date.split('T'))[0];
      let day = parseDate.split('-');
      return day[2]+"/"+day[1]+"/"+day[0];
    }

    useState(() => {
        getAllBuilding();
      }, []);

    return (
      <Dashboard>
        <Grid container component="main" className={classes.root}>
          <main className={classes.content}>
            <Container className={classes.container}>
              {!loading ? building.map((b, key) => {
                return (
                  <CardListBuilding 
                    key = {key}
                    id = {b._id}
                    code = {b.id}
                    name = {b.name}
                    direction = {b.direction}
                    status = {b.status}
                    listPriceId = {b._listPriceId}
                    numDep = {b.numDep}
                    date = {convertDate(b.createdAt)}                  
                  />
                )
              }): <Grid container justify="center"><CircularProgress /></Grid>}
              <CssBaseline />
              <ButtonPluss
                click = {onClickPluss}
              />
              <NewBuildingpModal
                open = {modalAdd}
                close = {OnCloseModal}
                update = {getAllBuilding}
                onClickCancel = {OnClickCancellBuilding}
              />
            </Container>
          </main>
        </Grid>
      </Dashboard>
      );
    }