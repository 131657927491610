import axios from 'axios';
import {GetSessionStore} from '../../Store/sessionStore';

export default async function GetUsers() {
    let userToken = GetSessionStore().token;
    try {
        const response = await axios({
            method: 'get',
            url: process.env.REACT_APP_URL_API+'/user',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
            }
        });
        return response.data;
    } catch (error) {
        console.log(error);
        return error;
    }
}