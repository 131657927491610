import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import SimpleTableDetailPriceRow from './simpleTableDetailPriceRow';


const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function SimpleTableDetailPrice(props) {
  const classes = useStyles();


  return (
    <TableContainer component={Paper} style={{marginTop: 10}}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Prenda</TableCell>
            <TableCell align="left">Precio</TableCell>
            <TableCell align="left">Categoria</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {props.clothes.length> 0 && props.clothes.map((c, key) => {
          return (
            <SimpleTableDetailPriceRow 
              key={key}
              id = {c.id}
              name={c.name}
              price={c.price}
              category={c.category}
              idList = {props.idList}
              onClickDelete={props.onClickDelete}
              onSaveRow = {props.onSaveRow}
              update={props.update}              
            />
          )
        })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}