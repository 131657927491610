import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';

import CardListPickUp from '../Components/Card/cardListPickUp';
import ButtonPluss from '../Components/Button/buttonPluss';
import NewPickUpModal from '../Components/Modal/newPickUpModal';
import Dashboard from '../Containers/dashboard';

import GetRecojos from '../Helpers/Gettters/getRecojos';

const useStyles = makeStyles((theme) => ({
    root: {
      height: '100vh',
      textAlign: 'center'
    },
      content: {
        flexGrow: 1,
        overflow: 'auto',
      },
      container: {

        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
      },
}));

 export default function PickUp() {
    const classes = useStyles();

    const [modalAdd, setModalAdd] = useState(false);
    const [recojos, setRecojos] = useState([]);
    const [loading, setLoading] = useState(true);

    function onClickPluss() {
      setModalAdd(true);
    }

    function OnCloseModal(){
      setModalAdd(false);
    }

    function OnClickCancellNewPickUp() {
      setModalAdd(false);
    }

    function orderSort(list) {
      list.sort((firstItem,secondItem)=>{
        return secondItem.id-firstItem.id
      })
      setRecojos(list);
    }

    const getAllRecojos = async () => {
      const list = await GetRecojos();
      orderSort(list);
      setLoading(false)
    }

    const convertDate = (date) => {
      let parseDate = (date.split('T'))[0];
      let day = parseDate.split('-');
      return day[2]+"/"+day[1]+"/"+day[0];
    }

    useState(() => {
      getAllRecojos();
    }, []);

    useState(() => {
      setInterval(()=>{
        console.log("setInterval")
        getAllRecojos();
      }, 300000);
    }, [])

    return (
      <Dashboard>
        <Grid container component="main" className={classes.root}>
          <main className={classes.content}>
            <Container className={classes.container}>             
              {!loading ? recojos.map((recojo, key) => {
                return (
                  <CardListPickUp 
                    key = {key}
                    code = {recojo.id}
                    date = {convertDate(recojo.day)}
                    hours = {recojo.hour}
                    orders = {recojo.orders}
                  />
                )
              }): <Grid container justify="center"><CircularProgress /></Grid>}
              <CssBaseline />
              <ButtonPluss
                click = {onClickPluss}
              />
              <NewPickUpModal
                open = {modalAdd}
                close = {OnCloseModal}
                update = {getAllRecojos}
                onClickCancel = {OnClickCancellNewPickUp}
              />
            </Container>
          </main>
        </Grid>
      </Dashboard>
      );
    }