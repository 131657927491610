import React, { useState} from 'react'; /*, {useState} */
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import swal from 'sweetalert';

import GetListPrice from '../../Helpers/Gettters/GetListPrice';
import CreateBuilding from '../../Helpers/Postters/createBuilding';

import SimpleAlert from '../Alert/SimpleAlert';


const useStyles = makeStyles((theme) =>({
 
    textContent: {
        color: '#383838',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '13px',
        textAlign: 'left'
    },
    estadoButton: {
        backgroundColor: '#3DBFEA',
        color: 'white',
        fontSize: '10px',
        width: '35vh',
        fontWeight: '300',
    },
    detalle: {
        padding: '2vh 0vh 0.5vh 1.5vh',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '13px',
        textAlign: 'left'
    },
    divider: {
        marginBottom: '1vh'
    },
    text: {
        color: '#383838',
        textAlign: 'left',
        fontWeight: '600',
        fontSize: '13px',
        marginTop: '2vh'
    },
    textField: {
        color: '#383838',
        textAlign: 'left',
        fontWeight: '600',
        fontSize: '13px',
        minWidth: '31vh'
    },
    date: {
        color: '#383838 !important',
        background: '#F0E8E8',
        padding: '1vh 2vh 1vh 3vh',
        borderRadius: '1vh',
        minWidth: '31vh',
        fontWeight: '600',
        fontSize: '13px !important',
    },
    guardar: {
        margin: '0vh 2.9vh 0.5vh 0vh',
        backgroundColor: '#F9B039',
        height: '4vh',
        fontSize: '12px',
        color: '#FFFFFF',
        fontWeight: '300',
        minWidth: '23vh',
        paddingTop: '20',
        paddingBottom: '20'
    },
    detalleEstado: {
        color: '#3DBFEA',
        fontStyle: 'normal',
        fontWeight: '900',
        fontSize: '13px',
        textAlign: 'left'
    },
    textDetalle: {
        color: '#FFFFFF',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '13px',
        textAlign: 'left',
        marginTop: '2vh',
    },
    textFieldDetalle: {
        color: '#FFFFFF',
        textAlign: 'left',
        fontWeight: '600',
        fontSize: '13px'
    },
    paperDetalle: {
        background: '#3DBFEA',
        paddingBottom: '2vh',
        marginRight: '-4vh'
    }
}));

export default function NewBuildingModal(props) {
    const classes = useStyles();

    const [listPrice, setListPrice] = useState({});
    const [name, setName] = useState("")
    const [direction, setDirecion] = useState("");
    const [status, setStatus] = useState(true);
    const [numDep, setNumDep] = useState(0);
    const [listPriceId, setListPriceId] = useState("");
    const [process, setProcess] = useState(false);

    const getListPrice = async () => {
        const listPrice_ = await GetListPrice();
        //  getUser(order_._userId);
        setListPrice(listPrice_); 
        console.log("getListPrice", listPrice_);
    }

    const onChangeName = (event) => {
        setName(event.target.value);
    }
    
    const onChangeDirection = (event) => {
        setDirecion(event.target.value);
    }

    const onChangeStatus = (event) => {
        setStatus(event.target.checked);
    }

    const onChangeNumDep = (event)=> {
        setNumDep(event.target.value);
    }

    const onChangeListPriceId = (event) => {
        setListPriceId(event.target.value);
    }
    
    const clearFields = () => {
        setName("");
        setDirecion("");
        setStatus(true);
        setNumDep(0);
        setListPriceId("");
    }

    const onClickCancel = () => {
      clearFields(); 
      props.onClickCancel();
    }

    const onClickSave = async () => {
        setProcess(true);
        if(name.length>0 &&
            direction.length>0 &&
            numDep>0 &&
            listPriceId.length>0) {
                const data = {
                    name: name,
                    direction: direction,
                    status: status,
                    listPriceId: listPriceId,
                    numDep: numDep
                }
                
                const response = await CreateBuilding(data);
                if(response) {
                    swal("Edificio Guardado!", {
                        icon: "success",
                    }); 
                    clearFields();
                    props.update();
                    props.close();
                } else {
                    swal("Tuvimos problemas al guardar el Building :(. Intentelo mas tarde", {
                        icon: "error",
                      });  
                }

            } else {
                SimpleAlert("¡Atencion!", "Ingrese todo los datos", "warning");  
            }
            setProcess(false);
    }

    useState(() => {
        getListPrice();
    }, [])

    return (
        <FormControl>
            <Grid container spacing={2}>
                <Dialog 
                    maxWidth = "md"
                    aria-labelledby="customized-dialog-title"
                    open={props.open}
                    onClose={props.close}>
                        
                    <DialogContent>
                        <Grid container spacing={2} justify="center" direction="row" className={classes.root}    >
                            <Grid item xs={12} md={5} lg={4}>
                                <TextField 
                                    label="Nombre" 
                                    className={classes.textContent}
                                    value = {name}
                                    fullWidth={true}
                                    onChange={onChangeName}
                                />
                            </Grid> 
                            <Grid item xs={12} md={7} lg={8}>
                                <TextField 
                                    fullWidth = {true}
                                    label="Direccion" 
                                    className={classes.textContent}
                                    value = {direction}
                                    onChange={onChangeDirection}
                                />
                            </Grid> 
                            <Grid item xs={12} md={2} lg={3}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            onChange={onChangeStatus}
                                            checked={status}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label="Estado"
                                />
                            </Grid>
                            <Grid item xs={12} md={3} lg={3}>
                                <TextField 
                                    onChange={onChangeNumDep}
                                    id="standard-basic" 
                                    label="Num Departamentos" 
                                    className={classes.textContent}
                                    value = {numDep}
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    label = "Lista Precios"
                                    id="demo-simple-select"
                                    value={listPriceId}
                                    onChange={onChangeListPriceId}
                                >
                                    {listPrice.length >0 ? listPrice.map((list) => {
                                        return (
                                            <MenuItem key={list._id} value={list._id}>{list.name}</MenuItem>
                                        )
                                    }):<MenuItem value={""}>Sin Lista de Precios</MenuItem>}
                                </Select>
                            </Grid>
                            <Grid item xs={12} style={{marginTop: 15}}>
                                {!process ? <Grid container justify="center">
                                    <Grid item xs={12} md={2}>
                                        <Button onClick={onClickSave} variant="contained" color="primary">Guardar</Button>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Button onClick={onClickCancel} variant="contained" color="secondary">cancelar</Button>
                                    </Grid>
                                </Grid> :<Grid container justify="center"><CircularProgress /></Grid>}
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </Grid>
        </FormControl>
        
    )
}

