import Cookies from 'js-cookie';
import base64 from 'base-64';

export const GetSessionStore = () => {
    try {
        const session = Cookies.getJSON('lavabo_session');
        if(session) {
            const data = JSON.parse(base64.decode(session));
            if(data.auth) {
              return data;
            } else {
                return false;
            }
          }
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const setSessionStore = (data) => {
    const lastSession = Cookies.get('lavabo_session');
    try {
        if(lastSession && lastSession.auth) {
            Cookies.remove('lavabo_session')
        }

        let session = base64.encode(JSON.stringify(data));
        Cookies.set('lavabo_session', session,{ expires: 720 });

    } catch (error) {
        console.log(error);
        return false
    }
}

export const ClearSessionStore = () => {
    Cookies.remove('lavabo_session');
} 

export default {GetSessionStore, setSessionStore, ClearSessionStore}