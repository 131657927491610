import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import SimpleAlert from '../Alert/SimpleAlert';

const useStyles = makeStyles((theme) => ({
    card: {
        position: 'relative',
        borderRadius: '6px',
        backgroundColor: '#F0E8E8',
        padding: '1vh',
        marginBottom: '1vh',
    },
    textHeader: {
        color: '#000000',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '10px',
    },
    select: {
        minWidth: '16vh'
    },
    contentSelect: {
        color: '#000000',
        fontSize: '12px'
    },
    text: {
        color: '#FFFFFF',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '600',
    },
    buttonDetalle: {
        margin: '1.2vh',
        backgroundColor: '#3DBFEA',
        height: '2.5vh',
        fontSize: '11px',
        fontWeight: '300',
        color: '#FFFFFF'
    },
  }));

  export default function CardHeader(props) {
    const classes = useStyles();

    const [process, setProcess] = useState(true);
    const [clothes, setClothes] = useState({});
    const [quantity, setQuantity] = useState(0);
    const [price, setPrice] = useState(0);
    const [condition, setCondition] = useState(false);
    const [comment, setComment] = useState('');

    const onChangeClothes = (event) => {
        const clothes = event.target.value;
        console.log(clothes);
        setClothes(clothes);
    }

    const onChangeQuantity = (event) => {
        let quantity_ = event.target.value
        setQuantity(quantity_);
        let price_ = parseInt(clothes.price)*quantity_;
        setPrice(price_);
        // calculate price
    }

    const onChangePrice = (event) => {
        let p = parseInt(event.target.value);
        setPrice(p)        
        console.log(p)
        //setPrice(price);
    }

    const onChangeCondition = (event) => {
        let condition_ = event.target.value;
        setCondition(condition_);
    }

    const onChangeComment = (event) => {
        setComment(event.target.value);
    }

    const onClickSaveDetailOrder = () => {

        if(clothes && quantity>0 && price > 0) {
            const detail = {
                _pricesId: clothes.id,
                content: clothes.name,
                quantity: quantity,
                price: price,
                condition: condition,
                comment: comment,

            }
            console.log(detail);
            props.saveDetail(detail);
                
            //Clear data
            setClothes({});
            setQuantity(0);
            setPrice(0);
            setCondition(false);
            setComment('');
        } else {
            SimpleAlert("Datos Invalidos", "Verifique los datos que desea agregar", "warning")
        }
    }

    useEffect(()=> {
       if(props.listPrice) { 
            setProcess(false);
        }
    }, [props.listPrice])

    return (
        <Grid item sm={12} md={12} lg={12}>
            {process? <Grid container justify="center"><CircularProgress /></Grid>:<Paper className={classes.card}>
                <CardActions>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={2} md={3}>
                            <FormControl className={classes.select}>
                                <InputLabel id="demo-simple-select-readonly-label">Contenido</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-readonly-label"
                                        id="demo-simple-select-readonly"
                                        className={classes.contentSelect}
                                        onChange = {(event) => onChangeClothes(event)}
                                        value={clothes}
                                    >
                                        <MenuItem value="">
                                            <em>Ninguno</em>
                                        </MenuItem>
                                        {props.listPrice.clothes.length> 0 && props.listPrice.clothes.map((price, idx) => {
                                            return (
                                                <MenuItem key={idx} value={price}>{price.name}</MenuItem>
                                            )
                                            })
                                        }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2}>
                            <TextField 
                                id="quantity" 
                                label="Cantidad" 
                                className={classes.textHeader}
                                value = {quantity}
                                type = "number"
                                onChange = {onChangeQuantity}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2} md={2}>
                            <TextField 
                                id="price" 
                                label="Precio" 
                                className={classes.textHeader}
                                value = {price}
                                type = "number"
                                onChange={onChangePrice}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={2}>
                            <FormControl className={classes.select}>
                                <InputLabel id="demo-simple-select-readonly-label">Condicion</InputLabel>
                                <Select
                                    labelId="demo-simple-select-readonly-label"
                                    id="demo-simple-select-readonly"
                                    className={classes.textHeader}
                                    onChange = {(event) => onChangeCondition(event)}
                                    value={condition}
                                >
                                    <MenuItem value={1}>
                                        <em>SI</em>
                                    </MenuItem>
                                    <MenuItem value={0}>
                                        <em>NO</em>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {condition ===  1 && <Grid item xs={12} sm={3} md={3}>
                            <TextField 
                                id="comment" 
                                label="Comentario" 
                                className={classes.textHeader}
                                value = {comment}
                                onChange = {(event) => onChangeComment(event)}
                            />
                        </Grid>}
                    </Grid>
                </CardActions>
            </Paper>}
            <Grid container justify="flex-end">
                <Button disabled={process} variant="contained" onClick={onClickSaveDetailOrder} className={classes.buttonDetalle}>
                    Agregar detalle
                </Button>
            </Grid>
        </Grid>
    );
  }