import React from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';

import ProtectedRoute from '../Router/privateRoute';

// import Pages
import Login from '../Pages/Login';
import PickUp from '../Pages/PickUp';
import Building from '../Pages/Building';
import ListPrice from '../Pages/ListPrice';
import Reports from '../Pages/Reports';

const Connector = () => {
  return (
      <Router>
            <Route exact path="/">
                <Login />
            </Route>
            <Route exact path="/login">
                <Login />
            </Route>
            <ProtectedRoute exact 
                path="/home"
                component = {PickUp}
            />
            <ProtectedRoute exact 
                path="/pickup"
                component = {PickUp}
            />
            <ProtectedRoute exact 
                path="/building"
                component = {Building}
            />
            <ProtectedRoute exact 
                path="/listprice"
                component = {ListPrice}
            />
            <ProtectedRoute exact 
                path="/reports"
                component = {Reports}
            />
      </Router>
  )
}

export default Connector;
