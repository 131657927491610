import React, {useState} from 'react';

import HorizontalMenu from '../Components/Menu/horizantalMenu';
import VerticalMenu from '../Components/Menu/verticalMenu';


export default function Dash(props) {
    const [open, setOpen] = useState(false);
    const [iconsMenu] = useState([
        {
            id: 1,
            name: 'Inicio',
            icon: 'home',
            page: 'home',
            to: '/home'

          },
          { id: 2,
            name: 'Lista de Precios',
            icon: 'request_quote',
            page: 'listPrice',
            to: '/listprice'

          },
          { id: 3,
            name: 'Edificios',
            icon: 'apartment',
            page: 'building',
            to: '/building'

          },
          { id: 4,
            name: 'Recojos',
            icon: 'assignment',
            page: 'user',
            to: '/pickup'

          },
          { id: 5,
            name: 'Reportes',
            icon: 'leaderboard',
            page: 'reports',
            to: '/reports'

          },
          
            
    ])


    const onOpenHandle = () =>{
        setOpen(true);
    }

    const onCloseHandle = () => {
        setOpen(false);
    }

    return (
        <HorizontalMenu
            openHandle = {onOpenHandle}
            closeHandle = {onCloseHandle}
            open = {open}
            textMenu = "Lavabox"
            nav = {(<VerticalMenu />)}
            icons = {iconsMenu}
        >
            <div className="recojos" style={{minHeight: '33rem'}}>
                {props.children}
            </div>
            
        </HorizontalMenu>
    )
}