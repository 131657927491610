import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    card: {
        position: 'relative',
        borderRadius: '6px',
        backgroundColor: '#3DBFEA',
        padding: '1vh'
    },
    textHeader: {
        color: '#ffffff',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
    },
  }));

  export default function CardList(props) {
    const classes = useStyles();
   
    const formartDate = (date) => {
        const newDate = (date.getDate()<10?"0"+date.getDate():date.getDate())+"/"+(date.getMonth()<10?"0"+(date.getMonth()+1): (date.getMonth()+1))+"/"+(date.getFullYear());
        return newDate;
    }



    return (
      <Paper square elevation={6} className={classes.card}>
        <CardActions>
            <Grid container spacing={2} justify="center">
                <Grid item xs={12} md={6}>
                    <TextField 
                        id="standard-basic" 
                        label="Nombre" 
                        value={props.name} 
                        fullWidth={true}
                        onChange={props.onChangeName}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography className={classes.textHeader}>
                        {"Fecha: " + formartDate(props.date)}
                    </Typography>
                </Grid>
            </Grid>
        </CardActions>
      </Paper>
    );
  }