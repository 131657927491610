import React, {useState} from 'react';
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AccountCircle from "@material-ui/icons/AccountCircle";

import {ClearSessionStore} from '../../Store/sessionStore';
import {useHistory} from "react-router-dom";

export default function MenuIcon(props){
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const menuId = "primary-search-account-menu";

  const styles = {
    root: {
      position: 'absolute',
      right: '0',
      paddingRight: '2rem'
    }
  }

  const OncloseMenu = () => {
    setAnchorEl(false);
  }

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const OnClickCloseSession = () => {
    ClearSessionStore();
    history.push('/login');
  }

  const avatarMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={OncloseMenu}
    >
      {/*<Link to="/profile">*/}{/*<MenuItem >Perfil</MenuItem>*/}{/*</Link>*/}
        <MenuItem onClick={OnClickCloseSession}>Cerrar Sesión</MenuItem>
      </Menu>
  );

    
  return (
    <div style = {styles.root}>
      <IconButton
        edge="end"
        aria-label="Cuenta de Usuario"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
        {avatarMenu}
    </div>
  )
}