import React, {useState}  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import GetListPrice from '../../Helpers/Gettters/GetListPrice';
import UpdateBuilding from '../../Helpers/Updates/UpdateBuilding';

import SimpleAlert from '../Alert/SimpleAlert';

const useStyles = makeStyles((theme) =>({
    root: {
        paddingTop: 5,
        width: "100%",
        marginBottom: 10,
        background: "white",
        margin: 5
    },  
    textContent: {
        color: '#383838',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '13px',
        textAlign: 'left'
    },
    estadoButton: {
        backgroundColor: '#3DBFEA',
        color: 'white',
        fontSize: '10px',
        width: '35vh',
        fontWeight: '300',
    },
    detalle: {
        padding: '2vh 0vh 0.5vh 1.5vh',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '13px',
        textAlign: 'left'
    },
    divider: {
        marginBottom: '1vh'
    },
    text: {
        color: '#383838',
        textAlign: 'left',
        fontWeight: '600',
        fontSize: '13px',
        marginTop: '2vh'
    },
    textField: {
        color: '#383838',
        textAlign: 'left',
        fontWeight: '600',
        fontSize: '13px',
        minWidth: '31vh'
    },
    date: {
        color: '#383838 !important',
        background: '#F0E8E8',
        padding: '1vh 2vh 1vh 3vh',
        borderRadius: '1vh',
        minWidth: '31vh',
        fontWeight: '600',
        fontSize: '13px !important',
    },
    guardar: {
        margin: '0vh 2.9vh 0.5vh 0vh',
        backgroundColor: '#F9B039',
        height: '4vh',
        fontSize: '12px',
        color: '#FFFFFF',
        fontWeight: '300',
        minWidth: '23vh',
        paddingTop: '20',
        paddingBottom: '20'
    },
    detalleEstado: {
        color: '#3DBFEA',
        fontStyle: 'normal',
        fontWeight: '900',
        fontSize: '13px',
        textAlign: 'left'
    },
    textDetalle: {
        color: '#FFFFFF',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '13px',
        textAlign: 'left',
        marginTop: '2vh',
    },
    textFieldDetalle: {
        color: '#FFFFFF',
        textAlign: 'left',
        fontWeight: '600',
        fontSize: '13px'
    },
    paperDetalle: {
        background: '#3DBFEA',
        paddingBottom: '2vh',
        marginRight: '-4vh'
    }
}));

export default function OrderAccordion(props) {
    
    const classes = useStyles();
    const [listPrice, setListPrice] = useState({});
    const [loading, setLoading] = useState(true);
    const [process, setProcess] = useState(false);
    const [edit, setEdit] = useState(true)
    const [name, setName] = useState(props.name)
    const [direction, setDirecion] = useState(props.direction);
    const [status, setStatus] = useState(props.status);
    const [numDep, setNumDep] = useState(props.numDep);
    const [listPriceId, setListPriceId] = useState(props.listPriceId);
    
    const getListPrice = async () => {
        const listPrice_ = await GetListPrice();
        //  getUser(order_._userId);
        setListPrice(listPrice_); 
        console.log("getListPrice", listPrice_);
        console.log(props.listPriceId)
        setLoading(false);
    }

    const onClickEdit = () => {
        setEdit(false);
    }

    const onClickCancel = () => {
        setEdit(true);
    }

    const onClickSave = async () => {
        setProcess(true);
        const data = {
            _id: props.id,
            name: name,
            direction: direction,
            status: status,
            listPriceId: listPriceId,
            numDep: numDep
        }
        console.log(data);
        const response = await UpdateBuilding(data);
        if(response) {
            SimpleAlert("¡Genial!", "Se actualizo el Edificio", "success");   
        } else {
            SimpleAlert("Ups", "Hubo un error al actualizar el Edificio", "error");  
        }
        setEdit(true);
        setProcess(false);
    }

    const onChangeName = (event) => {
        setName(event.target.value);
    }
    
    const onChangeDirection = (event) => {
        setDirecion(event.target.value);
    }

    const onChangeStatus = (event) => {
        setStatus(event.target.checked);
    }

    const onChangeNumDep = (event)=> {
        setNumDep(event.target.value);
    }

    const onChangeListPriceId = (event) => {
        setListPriceId(event.target.value);
    }

    useState(() => {
        getListPrice();
    }, [])

    return (
        <Grid container>
            {!loading? <Grid container spacing={2} justify="center" direction="row" className={classes.root}    >
                <Grid item xs={12} md={5} lg={4}>
                    <TextField 
                        label="Nombre" 
                        className={classes.textContent}
                        value = {name}
                        fullWidth={true}
                        disabled={edit}
                        onChange={onChangeName}
                    />
                </Grid> 
                <Grid item xs={12} md={7} lg={8}>
                    <TextField 
                        fullWidth = {true}
                        label="Direccion" 
                        className={classes.textContent}
                        value = {direction}
                        disabled={edit}
                        onChange={onChangeDirection}
                    />
                </Grid> 
                <Grid item xs={12} md={2} lg={2}>
                    <FormControlLabel
                        control={
                            <Switch
                                onChange={onChangeStatus}
                                disabled={edit}
                                checked={status}
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label="Estado"
                    />
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <TextField 
                        fullWidth = {true}
                        onChange={onChangeNumDep}
                        disabled={edit}
                        id="standard-basic" 
                        label="Num Departamentos" 
                        className={classes.textContent}
                        value = {numDep}
                        type="number"
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <Select
                        disabled={edit}
                        labelId="demo-simple-select-label"
                        label = "Lista Precios"
                        id="demo-simple-select"
                        value={listPriceId}
                        onChange={onChangeListPriceId}
                    >
                        {listPrice.length >0 ? listPrice.map((list) => {
                            return (
                                <MenuItem key={list._id} value={list._id}>{list.name}</MenuItem>
                            )
                        }):<MenuItem value={""}>Sin Lista de Precios</MenuItem>}
                    </Select>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <TextField 
                        fullWidth = {true}
                        label="Fecha Creacion" 
                        className={classes.textContent}
                        value = {props.createdAt}
                        disabled = {true}
                    />
                </Grid> 
                <Grid item xs={12} style={{marginTop: 15}}>
                    {!process? <Grid container justify="center">
                        {edit && <Grid item xs={12} md={2}>
                            <Button onClick={onClickEdit} variant="contained">Editar</Button>
                        </Grid>}
                        {!edit && <Grid item xs={12} md={2}>
                             <Button onClick={onClickSave} variant="contained" color="primary">Guardar</Button>
                            
                        </Grid>}
                        {!edit && <Grid item xs={12} md={2}>
                            <Button onClick={onClickCancel} variant="contained" color="secondary">cancelar</Button>
                        </Grid>}
                    </Grid>:<Grid container justify="center"><CircularProgress /></Grid>}
                </Grid>
            </Grid>: <Grid container justify="center"><CircularProgress /></Grid> } 
        </Grid> 
    )
}