import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import swal from 'sweetalert';

import Dashboard from '../Containers/dashboard';
import NewListPriceModal from '../Components/Modal/newListPriceModal';
import ButtonPluss from '../Components/Button/buttonPluss';
import CardListPrice from '../Components/Card/cardListPrice';

import UpdateListPrice from '../Helpers/Updates/updateListPrice';
import GetListPrice from '../Helpers/Gettters/GetListPrice';

import './css/ListPrice.scss';

class ListPrice extends Component  {
    constructor(props) {
        super(props);
        this.state = {
            modalAdd: false,
            listPrice: [],
            loading: true
        }
    }

    componentDidMount = async() => {
        const list = await GetListPrice();
        this.setState({
            listPrice: list,
            loading: false
        });
    }
   
    convertDate = (date) => {
        let parseDate = (date.split('T'))[0];
        let day = parseDate.split('-');
        return day[2]+"/"+day[1]+"/"+day[0];
    }

    onCloseModal = () => {
        this.setState({
            modalAdd: false
        });

    }

    onClickPluss = () => {
        this.setState({
            modalAdd: true
        });
    }

    onSaveRow = async (id, data) => {
      
        let rowId = this.state.listPrice.findIndex( (l) => {
            return l._id === id;
        });

        let row = this.state.listPrice[rowId];

        let clothesId = row.clothes.findIndex((c) => {
            return c.id === data.id;
        });

        row.clothes[clothesId] = data;
        console.log(row.clothes);
        
        const update = {
            _id: id,
            clothes: row.clothes
        }

        const response = await UpdateListPrice(update);
        console.log(response);
        if(!response) {
            swal("Ups, Tuvimos un problema al actualizar los datos. Intentelo en unos minutos", {
                icon: "warning",
              }); 
        } 
        return true;
    }
    
    onSaveTitle = async (id, name) => {
        const data = {
            _id: id,
            name: name
        }
        const response = await UpdateListPrice(data);
        console.log(response);
        if(!response) {
            swal("Ups, Tuvimos un problema al actualizar los datos. Intentelo en unos minutos", {
                icon: "warning",
              }); 
        } 
        return true;
    }

    onClickAddRow = async (id) => {
        let rowId = this.state.listPrice.findIndex((l) => {
            return l._id === id
        });
        
        let clothes = this.state.listPrice[rowId].clothes;
        clothes.push({
            name: "",
            id: Math.floor(Math.random() * 1000000),
            price: 0,
            category: ""
        });
        
        const data = {
            _id: id,
            clothes: clothes
        }
        const response = await UpdateListPrice(data);
        console.log(response);
        if(!response) {
            swal("Ups, Tuvimos un problema al actualizar los datos. Intentelo en unos minutos", {
                icon: "warning",
              }); 
        } 
        this.componentDidMount();
    }

    onClickDeleteRow = async (listId, id) => {
        console.log(listId, id);
        let rowId = this.state.listPrice.findIndex((l) => {
            return l._id === listId;
        });

        let clothes = this.state.listPrice[rowId].clothes;
        let clothesId = clothes.findIndex((c) => {
            return c.id === id;
        })

        clothes.splice(clothesId, clothesId);
   //     console.log(clothes);
        const data = {
            _id: listId,
            clothes: clothes
        }
        console.log(data);
        const response = await UpdateListPrice(data);
        console.log(response);
        if(!response) {
            swal("Ups, Tuvimos un problema al actualizar los datos. Intentelo en unos minutos", {
                icon: "warning",
              }); 
        } 
        this.componentDidMount();
    }

    render() {
        return (
            <Dashboard>
                <Grid container component="main" className="root">
                    <main className="content">
                        <Container className="container">
                            {!this.state.loading ? this.state.listPrice.map((l, key) => {
                                return (
                                <CardListPrice 
                                    key = {key}
                                    id = {l._id}
                                    name = {l.name}
                                    date = {this.convertDate(l.createdAt)}  
                                    clothes = {l.clothes}    
                                    update = {this.componentDidMount}  
                                    
                                    onAddClick = {this.onClickAddRow}
                                    onClickDelete = {this.onClickDeleteRow}
                                    onSaveRow = {this.onSaveRow}
                                    onSaveTitle = {this.onSaveTitle}
                                />
                                )
                            }): <Grid container justify="center"><CircularProgress /></Grid>}
                            <ButtonPluss
                                click = {this.onClickPluss}
                            />
                        </Container>
                    </main>
                    <NewListPriceModal
                        open = {this.state.modalAdd}
                        close = {this.onCloseModal}
                        update = {this.componentDidMount}
                        onClickCancel = {this.onCloseModal}
                    />
                </Grid>
            </Dashboard>
        )
    }
}

export default ListPrice;