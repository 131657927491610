import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

const useStyles = makeStyles((theme) => ({
    closeButton: {
        marginBottom: theme.spacing(2),
        color: theme.palette.grey[500],
    },
    content: {
        marginTop: theme.spacing(4),
    }
  }));


export default function ModalSimple(props) {
    const classes = useStyles();

    return (
        <Dialog onClose={props.close} 
                open={props.open}
                maxWidth = "xs"
                fullWidth = {true}>
          <DialogContent className={classes.content}>
            {props.children}
          </DialogContent>
          <DialogActions className={classes.closeButton}>
            {props.buttons}
          </DialogActions>
        </Dialog>
        
      );
}