import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function SimpleTableAdd(props) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Contenido</TableCell>
            <TableCell align="left">Cantidad</TableCell>
            <TableCell align="left">Precio</TableCell>
            <TableCell align="left">Condición</TableCell>
            <TableCell align="left">Comentario</TableCell>
            <TableCell align="left">Acción</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            
              {props.orderDetail.length> 0 && props.orderDetail.map((d, key) => {
                return (
                  <TableRow key={key}>
                    <TableCell align="left">
                      <FormControl className={classes.select}>
                        <InputLabel id="demo-simple-select-readonly-label">Contenido</InputLabel>
                          <Select
                            labelId="demo-simple-select-readonly-label"
                            id="demo-simple-select-readonly"
                            className={classes.textHeader}
                            value={d._pricesId}
                            onChange = {(event) => props.editDetailsField(event, d.id, 'content')}
                          >
                            {props.clothes.length>0 && props.clothes.map((c, idx) => {
                              return (
                                <MenuItem key={idx} value={c.id}>
                                  <em>{c.name}</em>
                                </MenuItem>
                              )
                            }) }
                          </Select>
                        </FormControl>
                    </TableCell>
                    <TableCell align="left"> 
                      <TextField 
                        id="quantity" 
                        label="Cantidad" 
                        type = 'number'
                        onChange = {(event) => props.editDetailsField(event, d.id, 'quantity')}
                        value = {parseInt(d.quantity)}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField 
                        id="price" 
                        label="Precio" 
                        value = {(d.price)}
                        onChange = {(event) => props.editDetailsField(event, d.id, 'price')}
                        type = 'number'
                      />
                    </TableCell>
                    <TableCell align="left">
                      <FormControl className={classes.select}>
                        <InputLabel id="demo-simple-select-readonly-label">Condicion</InputLabel>
                          <Select
                            labelId="demo-simple-select-readonly-label"
                            id="demo-simple-select-readonly"
                            className={classes.textHeader}
                            onChange = {(event) => props.editDetailsField(event, d.id, 'condition')} 
                            value={d.condition}
                          >
                            <MenuItem value={1}>
                                <em>SI</em>
                                </MenuItem>
                            <MenuItem value={0}>
                              <em>NO</em>
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                     <TableCell align="left">
                      {d.condition === 1 &&<TextField 
                          id="comment" 
                          label="Comentario" 
                          onChange = {(event) => props.editDetailsField(event, d.id, 'comment')}
                          value = {d.comment}
                        />}
                    </TableCell>
                    <TableCell align="right">
                      <Grid container justify="flex-end">
                        <Grid item xs={5} >
                            <IconButton  aria-label="delete" className={classes.margin}>
                              <CloseIcon fontSize="small" onClick = {()=>{props.delete(d.id, d.price, d.quantity)}} />                       
                            </IconButton>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                )
                }) 
              }
        </TableBody>
      </Table>
    </TableContainer>
  );
}