import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
  
import LoginHelper from '../Helpers/Auth/Login';
import SimpleAlert from '../Components/Alert/SimpleAlert';
import {setSessionStore, GetSessionStore} from '../Store/sessionStore';

import {
  useHistory
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(/assets/img/fondo2.png)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  contentImage: {
    paddingTop: '20vh',
    position: 'absolute',
    paddingLeft: '7vh',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',    
  },
  avatar: {
    marginBottom: '1vh',
    width: '22.4vh',
    marginTop: '2vh'
  },
  form: {
    maxWidth: 500,
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(8),
  },
  submit: {
    background: "#3DBFEA",
    color: "#FFFFFF",
    margin: theme.spacing(3, 0, 2),
    paddingTop: '2vh',
    paddingBottom: '2vh',
    fontWeight: 'bold',
    borderRadius: '0vh',
    marginTop: '7vh'
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15.9px',
  },
  content: {
    textAlign: 'left',
    color: 'white',
    fontSize: '26px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: '29px',
  },
  input: {
    marginTop: theme.spacing(3),
    color: '#000000',
    marginBottom: theme.spacing(3)
  },
  inputCheck: {
    color: '#CCCCCC',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '10px',
    marginTop: '1vh',
    marginBottom: '1vh'
  }
}));
  
export default function Login(props) {
  const classes = useStyles();
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  function changeEmail(event) {
    let email_ = (event.target.value).trim();
    setEmail(email_);
  }

  function changePassword(event) {
    let password_ = event.target.value;
    setPassword(password_);
  }

  function changeRememberMe(event) {
    let rememberMe_ = event.target.checked;
    setRememberMe(rememberMe_);
  }

  async function OnClickIngresar() {
    const access = await  LoginHelper(email, password);
    // console.log(['access: ',access]);
    if(access.auth && access.token) {
      access.rememberMe = rememberMe;
      setSessionStore(access);
      history.push('/home')
    } else {
      SimpleAlert('Accesos incorrectos :(', 'Revise su correo o contraseña e intente nuevamente', 'error');
    }
  }

 

  useEffect( () => {
    const VerifyLogin = () =>{
      const session = GetSessionStore();
      if(session && session.auth && session.rememberMe) {
        history.push('/home')
      } 
    }
    VerifyLogin();
  }, [history]);



  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
        <Grid item xs={false} sm={4} md={6} className={classes.image}>
        </Grid>
        <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
          <Grid container justify="center">
            <div className={classes.paper}>
              <img src={'/assets/img/logo.png'} alt="logo" className={classes.avatar}/>
              <Typography component="h2" variant="subtitle1" className={classes.title}>
                I N I C I A R  -  S E S I Ó N
              </Typography>
              <form className={classes.form} noValidate>
                <TextField 
                  id="email"
                  name="email" 
                  label="USUARIO"
                  className={classes.input}
                  required
                  fullWidth
                  autoComplete="email"
                  autoFocus
                  type="email"
                  value = {email}
                  onChange={(event) => changeEmail(event)}
                /> 
                <TextField 
                  id="passwword"
                  name="password" 
                  label="CONTRASEÑA"
                  className={classes.input}
                  type="password"
                  required
                  fullWidth
                  autoComplete="current-password"
                  autoFocus
                  value = {password}
                  onChange = {(event) => changePassword(event)}
                /> 
                <FormControlLabel
                  className={classes.inputCheck}
                  control={ <Checkbox 
                    className={classes.inputCheck}
                    value={rememberMe} 
                    onChange={(event) => changeRememberMe(event)} 
                    color="primary" />
                  }
                  label="Mantener sesión iniciada"
                />
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick = {OnClickIngresar}
                >
                  INGRESAR
                </Button>
              </form>
            </div>
          </Grid>
          
        </Grid>
      </Grid>
    );
  }