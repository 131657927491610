import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function SimpleTableDetail(props) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Contenido</TableCell>
            <TableCell align="left">Cantidad</TableCell>
            <TableCell align="left">Precio</TableCell>
            <TableCell align="left">Condición</TableCell>
            <TableCell align="left">Comentario</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            
              {props.orderDetail  && props.orderDetail.map((d, key) => {
                return (
                  <TableRow key={key}>
                    <TableCell align="left">
                      <TextField 
                        id="content" 
                        label="Contenido"
                        value = {d.content}
                        disabled={true}
                      />
                    </TableCell>
                    <TableCell align="left"> 
                      <TextField 
                        id="quantity" 
                        label="Cantidad" 
                        type = 'number'
                        value = {parseInt(d.quantity)}
                        disabled={true}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField 
                        id="price" 
                        label="Precio" 
                        value = {(d.price/100)}
                        type = 'number'
                        disabled={true}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <FormControl className={classes.select}>
                        <InputLabel id="demo-simple-select-readonly-label">Condicion</InputLabel>
                          <Select
                            labelId="demo-simple-select-readonly-label"
                            id="demo-simple-select-readonly"
                            className={classes.textHeader}
                            disabled={true}
                            value={d.condition}
                          >
                            <MenuItem value={1}>
                                <em>SI</em>
                                </MenuItem>
                            <MenuItem value={0}>
                              <em>NO</em>
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                     <TableCell align="left">
                      {d.condition === 1 &&<TextField 
                          id="comment" 
                          label="Comentario" 
                          onChange = {(event) => props.editDetailsField(event, d.id, 'comment')}
                          value = {d.comment}
                          disabled={true}
                        />}
                    </TableCell>
                  </TableRow>
                )
                }) 
              }
        </TableBody>
      </Table>
    </TableContainer>
  );
}