import React, { Component} from 'react'; /*, {useState} */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import swal from 'sweetalert';
import CircularProgress from '@material-ui/core/CircularProgress';

import CardHeaderListPrice from '../Card/cardHeaderListPrice';
import NewListPriceCard from '../Card/newListPriceCard.js';
import SimpleTableAddListPrice from '../Table/simpleTableAddListPrice';

import GetOrdersUnassigned from '../../Helpers/Gettters/GetOrdersUnAssigned';
import GetRecojoId from '../../Helpers/Gettters/GetRecojoId';
import CreateListPrice from '../../Helpers/Postters/createListPrice';

import './css/newPickUp.scss';


class NewPickUpModal extends Component {

   constructor(props) {
       super(props);
       this.state  = {
            name: '',
            createdAt: new Date(),
            newListStatus: true,
            newListDetail: false,
            detailListPrice: [], // array temporal de detalle de ordenes  ,
            process: false      
        }
   }

   componentDidMount = async() => {
      
    }

    onChangeName = (event) => {
        this.setState({
            name: event.target.value
        });
    }

    // obtiene toda las ordenes sin asignar
    GetOrders = async () => {
        const ordersUnassigned = await GetOrdersUnassigned();
        console.log("GetOrders", ordersUnassigned)
        return ordersUnassigned;
    }

    // obtiene el id del recojo
    GetIdPickUp = async () => {
        const recojoId = await GetRecojoId();
        return recojoId;
    }

   
    // muestra 
    onClickNewOrder = () => {
        this.setState({
            newListStatus: false,
            newListDetail: true
        });
    }

    // hook cuando el modal se cierra 
    OnExitModal = () => {
        //clear orders data
        this.clearFields();
        this.setState({
            detailListPrice: []
        })
    } 
    

    // limpia todo los campos
    clearFields = () => {
        this.setState({
            newListDetail: false,
            newListStatus: true
        });
    }

    // agrega detalle a un array temporal
    addDetail = (detail) => {
        console.log("addDetail", detail)
        detail.id = Math.floor(Math.random() * 1000000); 
        
        let tmp = this.state.detailListPrice;
        tmp.push(detail);
        console.log("tmp", tmp);

       
        this.setState({
            detailListPrice: tmp
        });
    }

    // editar los campos del detalle que se esta agregando
    editDetailsField = (event, id, type) => {
        const value = event.target.value;
        console.log("value", value)

        const detailIndex = this.state.detailListPrice.findIndex(d => {
            return d.id === id;
        });
 
        const detail = {
            ...this.state.detailListPrice[detailIndex]
        }

        switch (type) {
            case 'name':
                detail.name = value;
                break;
            case 'price':
                detail.price = value;
                break;
            case 'category':
                detail.category = value;
                break;
            default:
                break;
        }
    
        const details = [...this.state.detailListPrice];
        details[detailIndex] = detail;
        console.log(details);
        this.setState({
            detailListPrice: details
        });
    }

    onClickDeleteDetail = (id) =>{
        swal({
            title: "Eliminar Detalle",
            text: "¿Esta seguro que desea eliminar el elemento?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                let new_ = this.state.detailListPrice.filter((detail) => {
                    return detail.id !== id;
                });

                this.setState({
                    detailListPrice: new_
                });
                swal("¡Elemento Eliminado!", {
                    icon: "success",
                  }); 
            }
          });
    }

    // guarda lista de precios
    onClickSaveList = async () => {
        this.setState({
            process: true
        });
        if(this.state.name.length>0 && this.state.detailListPrice.length>0) {
            swal({
                title: "Guardar Lista",
                text: "¿Esta seguro que desea guardar la lista de precios?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              })
            .then(async (willSave) => {
                if (willSave) {
                   const listPrice = {
                        name: this.state.name,
                        createdAt: this.state.createdAt,
                        clothes: this.state.detailListPrice
                    }    
                    console.log(listPrice);
                   
                    const response = await CreateListPrice(listPrice);
                    console.log("response", response);
                    
                    if(response) {
                        swal("¡Lista de Precios Guardado!", {
                            icon: "success",
                        }); 
                        this.setState({
                            orderDetail: []
                        });
                        this.clearFields();
                        this.props.update();
                        this.props.close();
                    } else {
                        swal("Tuvimos problemas al guardar la lista de precios :(. Intentelo mas tarde", {
                            icon: "error",
                        });  
                    }
                }
            });
        } else {
            swal("¡Ingrese todo los campos!", {
                icon: "warning",
            });  
        }
        
    }

    render() {
        return (
            <FormControl>
            <Grid container spacing={2}>
                <Dialog 
                    onExit = {this.OnExitModal} 
                    maxWidth = "xl"
                    className = "modal"
                    aria-labelledby="customized-dialog-title"
                    open={this.props.open}
                    onClose={this.props.close}>
                    <DialogContent>
                        <NewListPriceCard   
                            name = {this.state.name}
                            onChangeName = {this.onChangeName}
                            date = {this.state.createdAt}
                        />
                        {this.state.newListStatus === true && <Grid container justify="flex-end">
                            <Button onClick={this.onClickNewOrder} color="primary" variant="contained" className="button">
                                Agregar prendas
                            </Button>
                        </Grid>}
                        {this.state.newListDetail && <Paper className="content">
                            <Grid container>  
                                <Grid container>
                                    <Grid item xs={12} sm={12} lg={12}>
                                        <Grid container justify="flex-start">
                                            <Typography gutterBottom variant="body1" className={"detalle"}>
                                                DETALLE
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Divider variant="middle" />
                           <Grid container>
                                <DialogContent className={"contentDialog"}>
                                    <CardHeaderListPrice
                                        saveDetail = {this.addDetail}
                                    />
                                    <SimpleTableAddListPrice 
                                        listPriceDetail = {this.state.detailListPrice}
                                    
                                        editDetailsField = {this.editDetailsField}
                                        delete = {this.onClickDeleteDetail}
                                    />
                                </DialogContent>
                            </Grid>
                        </Paper>}
                    </DialogContent>
                    {!this.state.process ? <Grid container> 
                        <Grid item xs={12} sm={6} lg={6}>
                            <Grid container justify="flex-start">
                                <Button onClick={this.props.onClickCancel} variant="contained" className="cancelar">
                                    Cancelar 
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                            <Grid container justify="flex-end">
                                <Button onClick={this.onClickSaveList} variant="contained" className="guardar">
                                    Guardar Lista
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>:<Grid container justify="center"><CircularProgress /></Grid>}
                </Dialog>
            </Grid>
            </FormControl>
      );
    }
}

export default (NewPickUpModal)