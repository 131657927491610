import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';


const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function SimpleTableDetailPriceRow(props) {
  const classes = useStyles();
  const [edit, setEdit] = useState(true);
  
  const [name, setName] = useState(props.name);
  const [price, setPrice] = useState(props.price);
  const [category, setCategory] = useState(props.category === undefined?"": props.category);

  const onClickEdit = () => {
    setEdit(false);
  }

  const onClickCancell = () => {
    props.update();
    setEdit(true);
    setName(props.name);
    setPrice(props.price);
    setCategory(props.category === undefined?"": props.category);
  }

  const onClickSave = async () => {
    const data = {
      id: props.id,
      name: name,
      price: price,
      category: category
    }
    
     await props.onSaveRow(props.idList, data);
    setEdit(true);
    props.update();
  }

  const onChangeName = (event) => {
    setName(event.target.value);
  }

  const onChangePrice = (event) => {
    setPrice(event.target.value);
  }

  const onChangeCategory = (event) => {
    setCategory(event.target.value);
  }

  const onClickDelete = () => {
    props.onClickDelete(props.idList, props.id);
  }

  useEffect(() => {
    if(name.length === 0 || price === 0 || category.length === 0 ) {
      setEdit(false);
    } 
  }, [name.length, price, category.length])
  
  return (
    <TableRow>
      <TableCell align="left">
        <TextField 
            id="name" 
            label="Nombre" 
            value = {name}
            disabled = {edit}
            onChange = {onChangeName}
        />
      </TableCell>
      <TableCell align="left"> 
        <TextField 
          id="price" 
          label="Precio" 
          type = 'number'
          value = {parseInt(price)}
          disabled = {edit}
          onChange={onChangePrice}
        />
      </TableCell>
      <TableCell align="left">
        <FormControl className={classes.select}>
            <InputLabel id="demo-simple-select-readonly-label">Categoria</InputLabel>
            <Select
              labelId="category"
              id="Category"
              className={classes.textHeader}
              disabled = {edit}
              value={category}
              onChange={onChangeCategory}
            >
              <MenuItem value="">Sin Categoria</MenuItem>
              <MenuItem value="ROPA FORMAL">
                <em>ROPA FORMAL</em>
              </MenuItem>
              <MenuItem value="PARA EL FRIO">
                <em>PARA EL FRIO</em>
              </MenuItem>
              <MenuItem value="ROPA CASUAL">
                <em>ROPA CASUAL</em>
              </MenuItem>
              <MenuItem value="VESTIDO">
                <em>VESTIDO</em>
              </MenuItem>
              <MenuItem value="NIÑOS">
                <em>NIÑOS</em>
              </MenuItem>
              <MenuItem value="OTROS">
                <em>OTROS</em>
              </MenuItem>
              <MenuItem value="ALMOHADAS Y COJINES">
                <em>ALMOHADAS Y COJINES</em>
              </MenuItem>
              <MenuItem value="FRAZADAS Y EDREDONES">
                <em>FRAZADAS Y EDREDONES</em>
              </MenuItem>
              <MenuItem value="SABANAS Y FUNDAS">
                <em>SABANAS Y FUNDAS</em>
              </MenuItem>
              <MenuItem value="LAVADO Y DOBLADO AL SECO">
                <em>LAVADO Y DOBLADO AL SECO</em>
              </MenuItem>
              <MenuItem value="CALZADOS, CARTERAS Y CORREAS">
                <em>CALZADOS, CARTERAS Y CORREAS</em>
              </MenuItem>
            </Select>
          </FormControl>
      </TableCell>
      
        <TableCell align="right">
          <Grid container justify="flex-end">
            <Grid item xs={5} >
              {edit && <IconButton onClick={onClickEdit} aria-label="edit">
                <EditIcon  fontSize="small" />
              </IconButton>}
              {!edit && <IconButton  onClick={onClickCancell}  aria-label="delete" className={classes.margin}>
                <CloseIcon fontSize="small"  />                       
              </IconButton>}
              {!edit && <IconButton  onClick={onClickDelete}  aria-label="delete" className={classes.margin}>
                <DeleteIcon fontSize="small"  />                       
              </IconButton>}
              {!edit && <IconButton onClick={onClickSave}>
                <CheckIcon fontSize="small"  />
              </IconButton>}
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>

  );
}