import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

import BuildingAccordion from '../Accordion/buildingAccordion';

const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: '6px',
        backgroundColor: '#FDF4F4',
        marginBottom: 15,
        height: '100%'
    },
    cardContent: {
        backgroundColor: '#F0E8E8',
    },
    textHeader: {
        color: '#383838',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    textTableHeader: {
        fontWeight: '500',
        fontStyle: 'normal',
        fontSize: '13px',
    },
    icon: {
        color: '#383838'
    },
    buttonAcept: {
      background: '#67C4F2',
      borderRadius: 3,
      border: 0,
      color: 'white',
      fontSize:15,
      height: 40,
      padding: '0 30px',
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      margin: theme.spacing(1),
    } 
  }));

  export default function CardListPickUp(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
  
    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

    return (
      <Paper elevation={6} square className={classes.card}>
        <CardActions>
            <Grid item xs={4} sm={4} md={4}>
                <Typography className={classes.textHeader}>
                    {"Codigo de Edificio: " + props.code}
                </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
                <Typography className={classes.textHeader}>
                    {"Nombre: " + props.name}
                </Typography>
            </Grid>
            <Grid item xs={3} sm={3} md={3}>
                <Typography className={classes.textHeader}>
                    {"Estado: "+(props.status?"Activo": "Inactivo")}
                </Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
                <IconButton className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more">
                        <ExpandMoreIcon />
                </IconButton>
            </Grid>
        </CardActions>                
        <Collapse in={expanded} timeout="auto" unmountOnExit className={classes.cardContent}>
          <CardContent>
                <Grid container spacing={2}>
                  <BuildingAccordion 
                    id = {props.id}
                    name = {props.name}
                    direction = {props.direction}
                    status = {props.status}
                    numDep = {props.numDep}
                    listPriceId = {props.listPriceId}
                    createdAt = {props.data}
                  />
                </Grid>
          </CardContent>
        </Collapse>
      </Paper>
    );
  }