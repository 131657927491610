import axios from 'axios';
import {GetSessionStore} from '../../Store/sessionStore';

export default async function UpdateOrder(data) {
    let userToken = GetSessionStore().token;
    try {
        const response = await axios({
            method: 'put',
            url: process.env.REACT_APP_URL_API+'/listprice/'+data._id,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
            },
            data: JSON.stringify(data)
        });
        return response;
    } catch (error) {
        console.log(error);
        return false;
    }
}