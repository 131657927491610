import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';

import SimpleAlert from '../Alert/SimpleAlert';

const useStyles = makeStyles((theme) => ({
    card: {
        position: 'relative',
        borderRadius: '6px',
        backgroundColor: '#F0E8E8',
        padding: '1vh',
        marginBottom: '1vh',
    },
    textHeader: {
        color: '#000000',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '10px',
    },
    select: {
        minWidth: '16vh'
    },
    contentSelect: {
        color: '#000000',
        fontSize: '12px'
    },
    text: {
        color: '#FFFFFF',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '600',
    },
    buttonDetalle: {
        margin: '1.2vh',
        backgroundColor: '#3DBFEA',
        height: '2.5vh',
        fontSize: '11px',
        fontWeight: '300',
        color: '#FFFFFF'
    },
  }));

  export default function CardHeader(props) {
    const classes = useStyles();
    const [name, setName] = useState("");
    const [price, setPrice] = useState(0);
    const [category, setCategory] = useState("");

    const onChangeName = (event) => {
        setName(event.target.value);
    }

    const onChangePrice = (event) => {
        setPrice(event.target.value);
    }

    const onChangeCategory = (event) => {
        let category_ = event.target.value;
        setCategory(category_);
    }

    const onClickSaveDetailOrder = () => {
       if(name && price>0 && category.length>0 ) {
           const detail = {
                name: name,
                price: price,
                category: category
           }

           console.log(detail);
           props.saveDetail(detail);
           setName("");
           setPrice(0);
           setCategory("");

        } else {
        SimpleAlert("Datos Invalidos", "Verifique los datos que desea agregar", "warning")
       }
        
    }

    return (
        <Grid item sm={12} md={12} lg={12}>
            <Paper className={classes.card}>
                <CardActions>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="name" 
                                label="Nombre" 
                                className={classes.textHeader}
                                value = {name}
                                onChange = {onChangeName}
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField 
                                id="price" 
                                label="Precio" 
                                className={classes.textHeader}
                                value = {price}
                                type = "number"
                                onChange={onChangePrice}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormControl className={classes.select}>
                                <InputLabel id="demo-simple-select-readonly-label">Categoria</InputLabel>
                                <Select
                                    labelId="demo-simple-select-readonly-label"
                                    id="demo-simple-select-readonly"
                                    className={classes.textHeader}
                                    onChange = {onChangeCategory}
                                    value={category}
                                >
                                    <MenuItem value="">
                                        <em>Ninguno</em>
                                    </MenuItem>
                                    <MenuItem value="ROPA FORMAL">
                                        <em>ROPA FORMAL</em>
                                    </MenuItem>
                                    <MenuItem value="PARA EL FRIO">
                                        <em>PARA EL FRIO</em>
                                    </MenuItem>
                                    <MenuItem value="ROPA CASUAL">
                                        <em>ROPA CASUAL</em>
                                    </MenuItem>
                                    <MenuItem value="VESTIDO">
                                        <em>VESTIDO</em>
                                    </MenuItem>
                                    <MenuItem value="NIÑOS">
                                        <em>NIÑOS</em>
                                    </MenuItem>
                                    <MenuItem value="OTROS">
                                        <em>OTROS</em>
                                    </MenuItem>
                                    <MenuItem value="ALMOHADAS Y COJINES">
                                        <em>ALMOHADAS Y COJINES</em>
                                    </MenuItem>
                                    <MenuItem value="FRAZADAS Y EDREDONES">
                                        <em>FRAZADAS Y EDREDONES</em>
                                    </MenuItem>
                                    <MenuItem value="SABANAS Y FUNDAS">
                                        <em>SABANAS Y FUNDAS</em>
                                    </MenuItem>
                                    <MenuItem value="LAVADO Y DOBLADO AL SECO">
                                        <em>LAVADO Y DOBLADO AL SECO</em>
                                    </MenuItem>
                                    <MenuItem value="CALZADOS, CARTERAS Y CORREAS">
                                        <em>CALZADOS, CARTERAS Y CORREAS</em>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </CardActions>
            </Paper>
            <Grid container justify="flex-end">
                <Button variant="contained" onClick={onClickSaveDetailOrder} className={classes.buttonDetalle}>
                    Agregar detalle
                </Button>
            </Grid>
        </Grid>
    );
  }