import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    card: {
        position: 'relative',
        borderRadius: '6px',
        backgroundColor: '#3DBFEA',
        padding: '1vh'
    },
    textHeader: {
        color: '#ffffff',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
    },
  }));

  export default function CardList(props) {
    const classes = useStyles();

    const formartDate = (date) => {
        const newDate = (date.getDate()<10?"0"+date.getDate():date.getDate())+"/"+(date.getMonth()<10?"0"+(date.getMonth()+1): (date.getMonth()+1))+"/"+(date.getFullYear());
        return newDate;
    }

    const formarHour = (date) => {
        const hour = ((date.getHours()<10?"0"+date.getHours():date.getHours())+":"+ (date.getMinutes()<10?"0"+date.getMinutes():date.getMinutes()));
        return hour;
    }

    return (
      <Paper square elevation={6} className={classes.card}>
        <CardActions>
            <Grid container spacing={2} justify="center">
                <Grid item xs={4} sm={4} md={4}>
                    <Typography className={classes.textHeader}>
                        {"Codigo de recojo: " + props.code}
                    </Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <Typography className={classes.textHeader}>
                        {"Dia: " + formartDate(props.date)}
                    </Typography>
                </Grid>
                <Grid item xs={3} sm={3} md={3}>
                    <Typography className={classes.textHeader}>
                        {"Hora: " + formarHour(props.date)}
                    </Typography>
                </Grid>
            </Grid>
        </CardActions>
      </Paper>
    );
  }