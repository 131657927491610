import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import {GetSessionStore} from '../Store/sessionStore';

export default function PrivateRouter({ component: Component, ...rest }) {
    
    const auth = (props) => {
        const session = GetSessionStore();
       // console.log("auth", session);
        //let path = (props.location.pathname).split("/");

        if(session && session.auth) {
            return <Component {...props} />
        } else {
            return <Redirect to= '/login' />
        }

    }

    return (
        <Route
          {...rest}
          render={props =>
          auth(props)
        }
      />
    );
}