import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import SimpleTableDetailPrice from '../Table/simpleTableDetailPrice';

const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: '6px',
        backgroundColor: '#FDF4F4',
        marginBottom: 15,
        height: '100%'
    },
    cardContent: {
        backgroundColor: '#F0E8E8',
    },
    textHeader: {
        color: '#383838',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    textTableHeader: {
        fontWeight: '500',
        fontStyle: 'normal',
        fontSize: '13px',
    },
    icon: {
        color: '#383838'
    },
    buttonAcept: {
      background: '#67C4F2',
      borderRadius: 3,
      border: 0,
      color: 'white',
      fontSize:15,
      height: 40,
      padding: '0 30px',
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      margin: theme.spacing(1),
    } 
  }));

  export default function CardListPrice(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    
    const [edit, setEdit] = useState(true);
    const [process] = useState(false);
    const [name, setName] = useState(props.name);

    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

    const onClickCancel = () => {
      props.update();
      setEdit(true);
      setName(props.name);
     
    }

    const onClickSave = () => {
      props.onSaveTitle(props.id, name);  
      props.update();
      setEdit(true);
    }

    const onClickEdit = () => {
      setEdit(false);
    }

    const onChangeName = (event) => {
      setName(event.target.value);
    }

    const onClickAdd = () => {
      props.onAddClick(props.id);
    }

    

    return (
      <Paper elevation={6} square className={classes.card}>
        <CardActions>
            <Grid item xs={6}>
                <Typography className={classes.textHeader}>
                    {"Nombre: " + props.name}
                </Typography>
            </Grid>
            <Grid item xs={5}>
                <Typography className={classes.textHeader}>
                    {"Fecha de Creacion: " + props.date}
                </Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
                <IconButton className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more">
                        <ExpandMoreIcon />
                </IconButton>
            </Grid>
        </CardActions>                
        <Collapse in={expanded} timeout="auto" unmountOnExit className={classes.cardContent}>
          <CardContent>
                <Grid container spacing={2} alignItems="flex-end">
                  <Grid item xs={12} md={8}> 
                    <TextField 
                      id="standard-basic" 
                      label = "Nombre"
                      className={classes.textField} 
                      value = {name} 
                      fullWidth = {true}
                      disabled={edit}
                      onChange={onChangeName}
                    />
                  </Grid>   
                  <Grid item xs={2} >
                    <IconButton onClick={onClickAdd} disabled={edit} aria-label="edit">
                      <AddCircleIcon fontSize="small" />
                    </IconButton>  
                  </Grid>             
                  <SimpleTableDetailPrice 
                    clothes = {props.clothes}
                    update = {props.update}
                    id = {props.id}
                    idList = {props.id}
                    onClickDelete = {props.onClickDelete}
                    onSaveRow = {props.onSaveRow}
                  />      
                </Grid>
                
                <Grid item xs={12}>
                  <Grid container justify="center" direction="row">
                    <Grid item xs={12} style={{marginTop: 15}}>
                      {!process? <Grid container justify="center">
                        {edit && <Grid item xs={12} md={2}>
                          <Button onClick={onClickEdit} variant="contained">Editar</Button>
                        </Grid>}
                        {!edit && <Grid item xs={12} md={2}>
                          <Button onClick={onClickSave} variant="contained" color="primary">Guardar</Button>
                       
                        </Grid>}
                        {!edit && <Grid item xs={12} md={2}>
                          <Button onClick={onClickCancel} variant="contained" color="secondary">cancelar</Button>
                        </Grid>}
                      </Grid>:<Grid container justify="center"><CircularProgress /></Grid>}
                    </Grid>                    
                  </Grid>
                </Grid>
          </CardContent>
        </Collapse>
      </Paper>
    );
  }