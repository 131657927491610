const statusText = [
  {
    status: "REQUESTED",
    name:`Orden Aceptada`
  },
  {
    status: 'QUOTING',
    name: 'Ropa recogida'
  },
  {
    status: 'PENDING_PAYMENT',
    name: 'Pago pendiente'
  },
  {
    status: 'PAID',
    name: 'Orden pagada'
  },
  {
    status: 'ORDER_IN_LOCKER',
    name: 'Ropa en casillero'
  },
  {
    status: 'CLOTHES_PICKED_UP',
    name: 'Ropa recogida. Servicio completo'
  },
  {
    status: 'END',
    name: 'end'
  }
]

export default statusText;