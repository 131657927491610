import axios from 'axios';

// metodo get
export default async function Login(email, password) {
    try {
        const response = await axios({
            method: 'post',
            url: process.env.REACT_APP_URL_API+"/auth/admin/login",
            headers: {'Content-Type': 'application/json'},
            data: {
                email: email,
                password: password
            }
        });
        return response.data;
    } catch (e) {
        console.log(e);
        return e;
    }
}