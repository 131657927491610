import React, {useState}  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import GetSpecificOrder from '../../Helpers/Gettters/GetSpecificOrder';
import GetSpecificUser from '../../Helpers/Gettters/GetSpecificUser';
import UpdateOrder from '../../Helpers/Updates/updateOrder';

import SimpleTableDetail from '../Table/simpleTableDetail';
import ModalSimple from '../Modal/modalSimple';
import StatusText from '../../Consts/statusText';
import SimpleAlert from '../Alert/SimpleAlert';

const useStyles = makeStyles((theme) =>({
    root: {
        paddingTop: 5,
        width: "100%",
        marginBottom: 10
    },  
    textContent: {
        color: '#383838',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '13px',
        textAlign: 'left'
    },
    estadoButton: {
        backgroundColor: '#3DBFEA',
        color: 'white',
        fontSize: '10px',
        width: '35vh',
        fontWeight: '300',
    },
    detalle: {
        padding: '2vh 0vh 0.5vh 1.5vh',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '13px',
        textAlign: 'left'
    },
    divider: {
        marginBottom: '1vh'
    },
    text: {
        color: '#383838',
        textAlign: 'left',
        fontWeight: '600',
        fontSize: '13px',
        marginTop: '2vh'
    },
    textField: {
        color: '#383838',
        textAlign: 'left',
        fontWeight: '600',
        fontSize: '13px',
        minWidth: '31vh'
    },
    date: {
        color: '#383838 !important',
        background: '#F0E8E8',
        padding: '1vh 2vh 1vh 3vh',
        borderRadius: '1vh',
        minWidth: '31vh',
        fontWeight: '600',
        fontSize: '13px !important',
    },
    guardar: {
        margin: '0vh 2.9vh 0.5vh 0vh',
        backgroundColor: '#F9B039',
        height: '4vh',
        fontSize: '12px',
        color: '#FFFFFF',
        fontWeight: '300',
        minWidth: '23vh',
        paddingTop: '20',
        paddingBottom: '20'
    },
    detalleEstado: {
        color: '#3DBFEA',
        fontStyle: 'normal',
        fontWeight: '900',
        fontSize: '13px',
        textAlign: 'left'
    },
    textDetalle: {
        color: '#FFFFFF',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '13px',
        textAlign: 'left',
        marginTop: '2vh',
    },
    textFieldDetalle: {
        color: '#FFFFFF',
        textAlign: 'left',
        fontWeight: '600',
        fontSize: '13px'
    },
    paperDetalle: {
        background: '#3DBFEA',
        paddingBottom: '2vh',
        marginRight: '-4vh'
    }
}));

export default function OrderAccordion(props) {
    
    const classes = useStyles();
    const [modalQuoting, setModalQuoting] = useState(false);
    const [modalOrderInLocker, setModalOrderInLocker] =useState(false);
    const [order, setOrder] = useState({});
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(true);
    const [nextStatus, setNextStatus] = useState('');
    const [deliveryDate, setDeliveryDate] = useState(new Date());
    const [locker, setLocker] = useState('');
    const [password, setPassword] = useState('');
    const [progress, setProgress] = useState(false);

    async function onChangeStatus(){
        console.log("onChangeStatus")
        switch (nextStatus) {
            case "QUOTING":
                setModalQuoting(true);
                break;
            case "ORDER_IN_LOCKER":
                setModalOrderInLocker(true);
                break;
            default:
                await changeStatusOrder();
        }
    }
  
    function OnCloseModalQuoting(){
        setModalQuoting(false);
    }

    async function changeStatusOrder(){
        setProgress(true);
        const newOrder = order;
        newOrder.status = nextStatus;
        newOrder.aditionalComment = false;
        if(deliveryDate) {newOrder.deliveryDate = deliveryDate}
        if(locker && locker.length>0) {newOrder.locker = locker}
        if(password && password.length>0) {newOrder.password = password}
        console.log(newOrder);
        const response = await UpdateOrder(newOrder);
        setModalQuoting(false);
        setModalOrderInLocker(false);
        if(response) {
            getOrder();
            SimpleAlert("¡Genial!", "Se actualizo el estado de la orden a: "+nextStatus, "success");   
        } else {
            SimpleAlert("Ups", "Hubo un error al actualizar el estado de la orden por favor intentelo en unos minutos", "error");  
        }
        setProgress(false);
    }

    function OnCloseModalOrderInLocker(){
        setModalOrderInLocker(false);
    
    }

    const getOrder = async () => {
        const order_ = await GetSpecificOrder(props.orderId);
        getUser(order_._userId);
        setOrder(order_); 
       
        console.log("getOrder", order_);
        if(props.mode === "detail") {
            for (let i = 0; i < StatusText.length; i++) {  
                if(order_.status === StatusText[i].status) {
                    if(i === StatusText.length-1) {
                        setNextStatus(StatusText[i].status)
                    } else {
                        setNextStatus(StatusText[i+1].status)
                    }
                }
            }
            
        }
    }

    const getUser = async (id) => {
        const user_ = await GetSpecificUser(id);
        setUser(user_);
        console.log("getUser", user_);
        setLoading(false)
    }

    const onChangeDeliveryDate = (date_) => {
        setDeliveryDate(date_);
        console.log(date_);
    }

    const onChangeLocker = (event) => {
        let newLocker = (event.target.value).trim();
        setLocker(newLocker);
    }

    const onChangePassword = (event) => {
        let newPass = event.target.value;
        setPassword(newPass);
    }

    useState(() => {
        getOrder();
    }, [])

    return (
        <Accordion className={classes.root}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                {!loading? <Grid container spacing={2} 
                 onClick={(event) => event.stopPropagation()}
                 onFocus={(event) => event.stopPropagation()}>
                    <Grid item xs={12} md={6} lg={3}>
                        <Typography className={classes.textContent}>
                            {"Nombre de usuario: " + user.local.firstName+" " +user.local.lastName}
                        </Typography>  
                    </Grid>   
                    <Grid item xs={12} md={6} lg={3}>
                        <Typography className={classes.textContent}>
                            {"Casillero: " + order.locker}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Typography className={classes.textContent}>
                            {"Código de orden: " + order.id}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Typography className={classes.textContent}>
                            {"Edificio: " + order.building}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Typography className={classes.textContent}>
                            {"Estado: " + order.status}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Typography className={classes.textContent}>
                            {"Valor total: S/." +(props.price/100)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Typography className={classes.textContent}>
                            {"Total de prendas: " + props.total}
                        </Typography>
                    </Grid>
                    {props.mode === "detail" && nextStatus !== "END" && <Grid item xs={12} sm={6} lg={6}>
                        <Grid container justify='flex-start'>
                            <Grid item xs={12} sm={6} lg={3}>
                                <Typography className={classes.textContent}>
                                    Cambiar estado:
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                {progress ? <Grid container justify="center"><CircularProgress /></Grid> : <Button disabled = {nextStatus==="PAID"?true:false} onClick={onChangeStatus} className={classes.estadoButton}>
                                    {nextStatus}
                                </Button>}
                            </Grid>
                        </Grid>
                    </Grid>}
                    {props.mode === "detail" && (order.status === "REQUESTED" || order.status === 'COLECTING') && <Grid item xs={12} sm={12} lg={12}>
                        <Typography className={classes.detalleEstado}>
                            DETALLE DE ESTADO
                        </Typography>
                    </Grid>}
                    {props.mode === "detail" && (order.status === "ORDER_IN_LOCKER" && order.status === "CLOTHES_PICKED_UP") && <Grid item xs={12} sm={12} lg={12}>
                        <Paper className={classes.paperDetalle}>
                            <Grid container>
                                <Grid item xs={12} sm={12} lg={4}>
                                    <Grid container justify="center">
                                        <Grid item xs={12} sm={6} lg={4}>
                                            <Typography className={classes.textDetalle}>
                                                Fecha de entrega:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={6}>
                                            <TextField id="standard-basic" className={classes.textFieldDetalle}/>
                                        </Grid>
                                    </Grid>    
                                </Grid>
                                <Grid item xs={12} sm={6} lg={4}>
                                    <Grid container justify="center">
                                        <Grid item xs={12} sm={6} lg={2}>
                                            <Typography className={classes.textDetalle}>
                                                Locker:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={6}>
                                            <TextField id="standard-basic" className={classes.textFieldDetalle}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={4}>
                                    <Grid container justify="center">
                                        <Grid item xs={12} sm={6} lg={3}>
                                            <Typography className={classes.textDetalle}>
                                                Contraseña:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={6}>
                                            <TextField id="standard-basic" className={classes.textFieldDetalle}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>}
                </Grid>: <Grid container justify="center"><CircularProgress /></Grid> }  
            </AccordionSummary>
            <AccordionDetails>
                {props.detail.length > 0 && <Grid container justify="flex-start"> 
                   <Grid item xs={12} sm={12} lg={12}> 
                        <Typography  variant="body1" className={classes.detalle}>
                            DETALLE
                        </Typography>
                        <Divider variante="middle" className={classes.divider}/>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12}>
                        <SimpleTableDetail orderDetail={props.detail}/>
                    </Grid>
                </Grid>}
            </AccordionDetails>
            <ModalSimple
                open = {modalQuoting}
                close = {OnCloseModalQuoting}
                buttons = {(
                    <Grid container justify="flex-end">
                        {!progress ? <Button variant="contained" className={classes.guardar} onClick={changeStatusOrder} >
                            Guardar
                        </Button>: <Grid container justify="center"><CircularProgress /></Grid>}
                    </Grid>
                )}
            >
                <Grid container justify="center">
                    <Grid item xs={6} sm={4} lg={4}>
                        <Typography className={classes.text}>
                            Fecha de entrega:
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={8} lg={8}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                clearable
                                onChange={onChangeDeliveryDate}
                                minDate={new Date()}
                                value={deliveryDate}
                                format="dd/MM/yyyy"
                            />
                        </MuiPickersUtilsProvider>
                        {/*<TextField
                            id="date"
                            type="date"
                            onChange = {(event) => onChangeDeliveryDate(event)}
                            className={classes.date}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />*/}
                    </Grid>
                </Grid>
            </ModalSimple>
            <ModalSimple
                open = {modalOrderInLocker}
                close = {OnCloseModalOrderInLocker}
                buttons = {(
                    <Grid container justify="flex-end">
                       {!progress? <Button variant="contained" className={classes.guardar} onClick={changeStatusOrder}>
                            Guardar
                        </Button>: <Grid container justify="center"><CircularProgress /></Grid>}
                    </Grid>
                )}
            >
                <Grid container justify="center">
                    <Grid item xs={12} sm={12} lg={12}>
                        <Typography className={classes.textContent}>
                            Ropa en casillero
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={4}>
                        <Typography className={classes.text}>
                            Número de casillero:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8} lg={8}>
                        <TextField id="standard-basic" className={classes.textField} onChange = {(event) => onChangeLocker(event)} />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={4}>
                        <Typography className={classes.text}>
                            Contraseña:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8} lg={8}>
                        <TextField id="standard-basic" className={classes.textField} onChange = {(event) => onChangePassword(event)} />
                    </Grid>
                </Grid>
            </ModalSimple>
        </Accordion>
    )
}