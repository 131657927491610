import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function SimpleTableAdd(props) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Prenda</TableCell>
            <TableCell align="left">Precio</TableCell>
            <TableCell align="left">Categoria</TableCell>
            <TableCell align="left">Acción</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            
              {props.listPriceDetail.length> 0 && props.listPriceDetail.map((l, key) => {
                return (
                  <TableRow key={key}>
                    <TableCell align="left"> 
                      <TextField 
                        id="name" 
                        label="Prenda" 
                        onChange = {(event) => props.editDetailsField(event, l.id, 'name')}
                        value = {l.name}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField 
                        id="price" 
                        label="Precio" 
                        value = {l.price}
                        onChange = {(event) => props.editDetailsField(event, l.id, 'price')}
                        type = 'number'
                      />
                    </TableCell>
                    <TableCell align="left">
                      <FormControl className={classes.select}>
                        <InputLabel id="demo-simple-select-readonly-label">Condicion</InputLabel>
                          <Select
                            labelId="demo-simple-select-readonly-label"
                            id="demo-simple-select-readonly"
                            className={classes.textHeader}
                            onChange = {(event) => props.editDetailsField(event, l.id, 'category')} 
                            value={l.category}
                          >
                            <MenuItem value="">
                              <em>Ninguno</em>
                            </MenuItem>
                            <MenuItem value="ROPA FORMAL">
                              <em>ROPA FORMAL</em>
                            </MenuItem>
                            <MenuItem value="PARA EL FRIO">
                              <em>PARA EL FRIO</em>
                            </MenuItem>
                            <MenuItem value="ROPA CASUAL">
                              <em>ROPA CASUAL</em>
                            </MenuItem>
                            <MenuItem value="VESTIDO">
                              <em>VESTIDO</em>
                            </MenuItem>
                            <MenuItem value="NIÑOS">
                              <em>NIÑOS</em>
                            </MenuItem>
                            <MenuItem value="OTROS">
                              <em>OTROS</em>
                            </MenuItem>
                            <MenuItem value="ALMOHADAS Y COJINES">
                              <em>ALMOHADAS Y COJINES</em>
                            </MenuItem>
                            <MenuItem value="FRAZADAS Y EDREDONES">
                              <em>FRAZADAS Y EDREDONES</em>
                            </MenuItem>
                            <MenuItem value="SABANAS Y FUNDAS">
                              <em>SABANAS Y FUNDAS</em>
                            </MenuItem>
                            <MenuItem value="LAVADO Y DOBLADO AL SECO">
                              <em>LAVADO Y DOBLADO AL SECO</em>
                            </MenuItem>
                            <MenuItem value="CALZADOS, CARTERAS Y CORREAS">
                              <em>CALZADOS, CARTERAS Y CORREAS</em>
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                    <TableCell align="right">
                      <Grid container justify="flex-end">
                        <Grid item xs={5} >
                            <IconButton  aria-label="delete" className={classes.margin}>
                              <CloseIcon fontSize="small" onClick = {()=>{props.delete(l.id)}} />                       
                            </IconButton>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                )
                }) 
              }
        </TableBody>
      </Table>
    </TableContainer>
  );
}