import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Zoom from '@material-ui/core/Zoom';

const useStyles = makeStyles((theme) => ({
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: '#3DBFEA',
    color: '#FFFFFF'
  },
}));

export default function ButtonPlus(props) {
  const classes = useStyles();
  const theme = useTheme();

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  return (
    <Zoom key={{color: 'primary'}}
    in={true}
    timeout={transitionDuration}
    style={{
      transitionDelay: `0ms`,
    }}
    unmountOnExit
>
      <Fab aria-label="add" className={classes.fab} onClick={props.click}>
        <AddIcon />
      </Fab>
    </Zoom>
  );
}