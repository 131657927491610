import React, { Component} from 'react'; /*, {useState} */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import DialogContent from '@material-ui/core/DialogContent';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Divider from '@material-ui/core/Divider';
import swal from 'sweetalert';

import CardHeader from '../Card/cardHeader';
import NewPickUpCard from '../Card/newPickUpCard.js';
import SimpleTableAdd from '../Table/simpleTableAdd';
import OrderAccordion from '../Accordion/orderAccordion';

import GetOrdersUnassigned from '../../Helpers/Gettters/GetOrdersUnAssigned';
import GetSpecificUser from '../../Helpers/Gettters/GetSpecificUser';
import GetRecojoId from '../../Helpers/Gettters/GetRecojoId';
import GetPricesClothes from '../../Helpers/Gettters/GetPricesClothes';
import CreateRecojo from '../../Helpers/Postters/createRecojo';
import GetSpecificOrder from '../../Helpers/Gettters/GetSpecificOrder';
import GetSpecificBilding from '../../Helpers/Gettters/getSpecificBuilding';
import GetSpecificListPrice from '../../Helpers/Gettters/GetSpecificListPrice';

import './css/newPickUp.scss';


class NewPickUpModal extends Component {

   constructor(props) {
       super(props);
       this.state  = {
            idPickUp: 0,
            createdAt: new Date(),
            newOrderStatus: true,
            newOrderDetail: false,
            ordersUnassigned: [], // ordenes sin aginar
            orderId: '',
            _orderId: '',
            userName: '',
            total: 0,
            price: 0,
            locker: '',
            status: '',
            detailOrder: [], // array temporal de detalle de ordenes
            orderDetail: [], // array de ordenes 
            userId: '',
            buildingId: '',
            listPriceId: '',
            listPrice: false       
        }
   }

   componentDidMount = async() => {
        const idPickup_ = await this.GetIdPickUp();
        const orders = await this.GetOrders();
       // const prices = await this.GetPrices();

        this.setState({
            idPickUp: idPickup_,
            ordersUnassigned: orders,
           // clothesPrices: prices
        });
        console.log("componentDidMount", this.state)
    }

    GetOrder = async () => {
        const order = await GetSpecificOrder(this.state._orderId); 
        console.log(order)
        const userId = order._userId;
        this.setState({
            userId: userId
        });
        await this.GetUser(userId);
    }

    GetUser = async (userId) => {
        const user = await GetSpecificUser(userId);
        console.log(user);
        const buildingId = user.local.buildingId;
        this.setState({
            buildingId: buildingId
        });
        await this.GetBuilding(buildingId);
    }

    GetBuilding = async (buildingId) => {
        const building = await GetSpecificBilding(buildingId);
        console.log(building);
        const listPriceId_ = building._listPriceId;
        this.setState({
            listPriceId: listPriceId_
        });
        await this.GetListPrice(listPriceId_)
    }

    GetListPrice = async (id) => {
        const listPrice_ = await GetSpecificListPrice(id);
        console.log(listPrice_);
        this.setState({
            listPrice: listPrice_
        })
    }

    // obtiene toda las ordenes sin asignar
    GetOrders = async () => {
        const ordersUnassigned = await GetOrdersUnassigned();
        console.log("GetOrders", ordersUnassigned)
        return ordersUnassigned;
    }

    // obtiene el id del recojo
    GetIdPickUp = async () => {
        const recojoId = await GetRecojoId();
        return recojoId;
    }

    // obtiene los precios de las ropas
    GetPrices = async () => {
        const prices = await GetPricesClothes();
        return prices;
    }
   
    // muestra 
    onClickNewOrder = () => {
        this.setState({
            newOrderStatus: false,
            newOrderDetail: true
        });
    }

    // hook cuando el modal se cierra 
    OnExitModal = () => {
        //clear orders data
        this.clearFields();
        this.setState({
            detailOrder: []
        })
    } 
    
    // cambiar id de la orden
    OnChangeOrderId  = async (event) => {
        let orderId_ = event.target.value;
    
        if(orderId_.length>0) {
            const orderSelected= this.state.ordersUnassigned.filter((order) => {
                return order.id === orderId_;
            });
        
            console.log("orderSelected", orderSelected)
            const user = await GetSpecificUser(orderSelected[0]._userId);
            console.log("user", user);
            
            this.setState({
                orderId: orderId_,
                _orderId: orderSelected[0]._id,
                userName: user.local.firstName+" "+user.local.lastName,
                locker: orderSelected[0].locker,
                status: orderSelected[0].status
            });

            this.GetOrder(orderSelected[0]._id);

        } else {
            this.setState({
                userName: '',
                locker: '',
                status: ''
            });
        }
    }

    //cancela la orden
    OnClickCancelOrder = () => {
        this.clearFields();

    }

    // limpia todo los campos
    clearFields = () => {
        this.setState({
            newOrderDetail: false,
            newOrderStatus: true,
            orderId: 0,
            userName: '',
            locker: '',
            status: '',
            price: 0,
            total: 0,
            _orderId: '',
            userId: '',
            buildingId: '',
            listPriceId: '',
            listPrice: false     
        });
    }

    // agrega detalle a un array temporal
    addDetail = (detail) => {
        console.log("addDetail", detail)
        detail.id = Math.floor(Math.random() * 1000000); 
        
        let tmp = this.state.detailOrder;
        tmp.push(detail);
        console.log("tmp", tmp);

        let priceTotal = parseInt(this.state.price);
        priceTotal += parseInt(detail.price);

        let total_ = parseInt(this.state.total);
        total_ += parseInt(detail.quantity);

        this.setState({
            detailOrder: tmp,
            price: priceTotal,
            total: total_
        });
        
        console.log("addDetail", this.state);
    }

    // cambios de los campos del detalle que se esta agregando
    editDetailsField = (event, id, type) => {
        const value = event.target.value;
        console.log("value", value, id, type)

       
        const detailIndex = this.state.detailOrder.findIndex(d => {
            return d.id === id;
        });
 
        const detail = {
            ...this.state.detailOrder[detailIndex]
        }

        switch (type) {
            case 'content':
                detail._pricesId = value;
                break;
            case 'quantity':
                let newQuantity = parseInt(value) - parseInt(detail.quantity);
                
                detail.quantity = value;

                let clothes = this.state.listPrice.clothes.filter((c) => {
                    return c.id === detail._pricesId;
                })
                //console.log(clothes);
                let NewPrice = (parseInt(clothes[0].price) * parseInt(value)) - parseInt(detail.price);
                detail.price = parseInt(clothes[0].price) * parseInt(value); 
                this.setState({
                    total: this.state.total + newQuantity,
                    price: this.state.price + NewPrice
                });

                break;
            case 'price':
                detail.price = value;
                break;
            case 'condition':
                detail.condition = value;
                break;
            case 'comment':
                detail.comment = value;
                break;
            default:
                break;
        }
    
        const details = [...this.state.detailOrder];
        details[detailIndex] = detail;
        console.log(details);
        this.setState({
            detailOrder: details
        });
    }

    onClickDeleteDetail = (id, price, quantity) =>{
        swal({
            title: "Eliminar Detalle",
            text: "¿Esta seguro que desea eliminar el elemento?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                let new_ = this.state.detailOrder.filter((detail) => {
                    return detail.id !== id;
                });

                this.setState({
                    detailOrder: new_,
                    total: this.state.total - parseInt(quantity),
                    price: this.state.price - parseInt(price)
                });
                swal("¡Elemento Eliminado!", {
                    icon: "success",
                  }); 
            }
          });
    }

    // guarda orden y su detall
    onClickSaveOrder = () => {
        let orders = this.state.orderDetail;

        let newOrder = {
            _orderId: this.state._orderId,
            totalPrice: this.state.price,
            totalClothes: this.state.total,
            detail: this.state.detailOrder,
            _listPriceId: this.state.listPriceId,
        }
        
        orders.push(newOrder);
       // console.log("newOrder", newOrder);
        this.setState({
            orderDetail: orders
        });   

        let newUnassignet = this.state.ordersUnassigned.filter((order) => {
            return order._id !== this.state._orderId
        })
        console.log("newUnassignet", newUnassignet)
        this.setState({
            ordersUnassigned: newUnassignet
        });
        
        console.log("onClickSaveOrder", orders);
        this.clearFields();
        this.setState({
            detailOrder: []
        })
    }

    // guarda recojo
    onClickSaveRecojo = async () => {
        swal({
            title: "Guardar Recojo",
            text: "¿Esta seguro que desea guardar el recojo?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then(async (willSave) => {
            if (willSave) {
               const recojo = {
                    id: this.state.idPickUp,
                    day: (this.state.createdAt.getDate()<10?"0"+this.state.createdAt.getDate():this.state.createdAt.getDate())+"/"+(this.state.createdAt.getMonth()<10?"0"+(this.state.createdAt.getMonth()+1): (this.state.createdAt.getMonth()+1))+"/"+(this.state.createdAt.getFullYear()),
                    hour: (this.state.createdAt.getHours()<10?"0"+this.state.createdAt.getHours():this.state.createdAt.getHours())+":"+((this.state.createdAt.getMinutes()<10?"0"+this.state.createdAt.getMinutes():this.state.createdAt.getMinutes())),
                    orders: this.state.orderDetail
                }    
                const response = await CreateRecojo(recojo);
                // console.log("response", response);
                
                if(response) {
                    swal("¡Recojo Guardado!", {
                        icon: "success",
                      }); 
                      this.setState({
                          orderDetail: []
                      });
                      this.clearFields();
                      this.props.update();
                      this.props.close();
                    } else {
                    swal("Tuvimos problemas al guardar el recojo :(. Intentelo mas tarde", {
                        icon: "error",
                      });  
                }
            }
          });
    }

    render() {
        return (
            <FormControl>
            <Grid container spacing={2}>
                <Dialog 
                    onExit = {this.OnExitModal} 
                    maxWidth = "md"
                    className = "modal"
                    aria-labelledby="customized-dialog-title"
                    open={this.props.open}
                    onClose={this.props.close}>
                    <DialogContent>
                        <NewPickUpCard 
                            code = {this.state.idPickUp}
                            date = {this.state.createdAt}
                        />
                        {this.state.newOrderStatus === true&& <Grid container justify="flex-end">
                            <Button onClick={this.onClickNewOrder} color="primary" variant="contained" className="button">
                                Agregar orden
                            </Button>
                        </Grid>}
                        
                        {this.state.orderDetail.length>0 ? this.state.orderDetail.map((order, key) => {
                            return (
                                <OrderAccordion
                                    key = {key}
                                    mode = "add"
                                    orderId = {order._orderId}
                                    price = {order.totalPrice}
                                    total = {order.totalClothes}
                                    detail = {order.detail}
                                />
                            )
                        }) :null}
                        {this.state.newOrderDetail && <Paper className="content">
                            <Grid container>
                                <Grid item xs={12} sm={6} lg={4}>
                                    <FormControl variant="filled" className="select">
                                        <InputLabel id="demo-simple-select-filled-label" className="text">Codigo de orden </InputLabel>
                                        <Select
                                            className="contentSelect"
                                            labelId="orderId"
                                            id="orderId"
                                            onChange = {(event) => this.OnChangeOrderId(event)}
                                            value = {this.orderId}
                                        >
                                            <MenuItem value="">
                                                <em>Ninguno</em>
                                            </MenuItem>
                                            {this.state.ordersUnassigned.length>0 && this.state.ordersUnassigned.map((order) => {
                                                return (
                                                    <MenuItem key={order._id} value={order.id}>{order.id}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={8}>
                                    <Paper className="paper">
                                        <Typography className="textParper">
                                        {"Nombre de usuario: "+this.state.userName}
                                        </Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={3} lg={3}>
                                    <Paper className="paper">
                                        <Typography className="textParper">
                                        {"Total de prendas: "+this.state.total}
                                        </Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={3} lg={3}>
                                    <Paper className={"paper"}>
                                        <Typography className={"textParper"}>
                                        {"Valor total: "+(this.state.price/100)}
                                        </Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={3} lg={3}>
                                    <Paper className={"paper"}>
                                        <Typography className={"textParper"}>
                                        {"Locker: " + this.state.locker}
                                        </Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={3} lg={3}>
                                    <Paper className={"paper"}>
                                        <Typography className={"textParper"}>
                                        {"Estado: " + this.state.status}
                                        </Typography>
                                    </Paper>
                                </Grid>
    
                                <Grid container>
                                    <Grid item xs={12} sm={12} lg={12}>
                                        <Grid container justify="flex-start">
                                            <Typography gutterBottom variant="body1" className={"detalle"}>
                                                DETALLE
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Divider variant="middle" />
                           {this.state.orderId.length>0 && <Grid container>
                                <DialogContent className={"contentDialog"}>
                                    <CardHeader
                                        prices = {this.state.clothesPrices}
                                        saveDetail = {this.addDetail}
                                        listPrice = {this.state.listPrice}
                                        listPriceId = {this.state.listPriceId}
                                    />
                                    <SimpleTableAdd 
                                        orderDetail = {this.state.detailOrder}
                                        clothes = {this.state.listPrice.clothes}
                                        total = {this.state.total}
                                        editDetailsField = {this.editDetailsField}
                                        delete = {this.onClickDeleteDetail}
                                    />
                                </DialogContent>
                            </Grid>}
                            <Grid container> 
                                <Grid item xs={12} sm={6} lg={6}>
                                    <Grid container justify="flex-start">
                                        <Button onClick={this.OnClickCancelOrder} variant="contained" className={"buttonCancelar"}>
                                            Cancelar orden
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={6}>
                                    <Grid container justify="flex-end">
                                        <Button variant="contained" onClick = {this.onClickSaveOrder} className={"buttonGuardar"}>
                                            Guardar orden
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>}
                    </DialogContent>
                    <Grid container> 
                        <Grid item xs={12} sm={6} lg={6}>
                            <Grid container justify="flex-start">
                                <Button onClick={this.props.onClickCancel} variant="contained" className="cancelar">
                                    Cancelar 
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                            <Grid container justify="flex-end">
                                <Button onClick={this.onClickSaveRecojo} variant="contained" className="guardar">
                                    Guardar recojo
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Dialog>
            </Grid>
            </FormControl>
      );
    }
}

export default (NewPickUpModal)