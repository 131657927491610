import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';

import { CSVLink } from "react-csv";

import Dashboard from '../Containers/dashboard';

import GetBuilding from '../Helpers/Gettters/getBuilding';
import GetUsers from '../Helpers/Gettters/GetUsers';
import GetSpecificBuilding from '../Helpers/Gettters/getSpecificBuilding';
import GetSpecificListPrice   from '../Helpers/Gettters/GetSpecificListPrice';
import GetOrders from '../Helpers/Gettters/GetOrders';

const useStyles = makeStyles((theme) => ({
    root: {
      height: '100vh',
      textAlign: 'center'
    },
      content: {
        flexGrow: 1,
        overflow: 'auto',
      },
      container: {

        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
      },
}));

 export default function Reports() {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState([]); 
    const [buildings, setBuildings] = useState([]);
    const [orders, setOrders] = useState([]);
    const [ordersDetail, setOrdersDetail] = useState([])

    const getUser = async() => {
      setLoading(true)
      console.log("users");
      const response = await GetUsers();
      const tmp = [];
      for (let i = 0; i < response.length; i++) {
        tmp.push({
          _id: response[i]._id,
          id: response[i].local.id,
          verified: response[i].local.verified,
          email: response[i].local.email,
          phone: response[i].local.phone,
          firstName: response[i].local.firstName,
          lastName: response[i].local.lastName,
          buildingId: response[i].local.buildingId,
          buildingName: (await GetSpecificBuilding(response[i].local.buildingId)).name,
          createdAt: response[i].local.createdAt
        })        
      }
      setUser(tmp);
      console.log("users",tmp);
      setLoading(false);
    }

    const getOrderDetail = async(orders) => {
      let tmp = [];
      for (let i = 0; i < orders.length; i++) {
        if(orders[i].commentsAdmin.length>0 && Array.isArray(orders[i].commentsAdmin) && typeof(orders[i].commentsAdmin) === 'object') {
          for (let j = 0; j < orders[i].commentsAdmin.length; j++) {
            tmp.push({
              _id: orders[i]._id,
              id: orders[i].id,
              building: orders[i].building,
              product: orders[i].commentsAdmin[j][0],
              quintity: orders[i].commentsAdmin[j][1],
              price: orders[i].commentsAdmin[j][2],
            })            
          }
        }       
      }

      console.log("orders detail", tmp);
      setOrdersDetail(tmp);
      setLoading(false);
    }

    const getBuildings = async() => {
      setLoading(true)
      const response = await GetBuilding();
      let tmp = [];
      for (let i = 0; i < response.length; i++) {
        tmp.push({
          _id: response[i]._id,
          id: response[i].id,
          name: response[i].name,
          listPriceId: response[i]._listPriceId,
          listPrice: (await GetSpecificListPrice(response[i]._listPriceId)).name
        })
      }
      console.log("buildings", tmp);
      setBuildings(response);
      setLoading(false);
    }

    const getDate = (date) => {
      const parseDate = new Date(date);
      const d = parseDate.getDate()<10 ? '0'+(parseDate.getDate()): parseDate.getDate();
      const m = (parseDate.getMonth()+1)<10 ? '0'+(parseDate.getMonth()+1): parseDate.getMonth()+1;
      return d+"/"+m+"/"+parseDate.getFullYear();
    }

    const getHour = (date) => {
      const parseDate = new Date(date);
      return parseDate.getHours()+":"+parseDate.getMinutes()+":"+parseDate.getSeconds();
    }

    useEffect(() => {
      const getOrder = async() => {
        setLoading(true);
        const response = await GetOrders();
        let tmp = [];
        for (let i = 0; i < response.length; i++) {
          tmp.push({
            _id: response[i]._id,
            id: response[i].id,
            seco: response[i].services.seco,
            planchado: response[i].services.planchado,
            edredon: response[i].services.edredones,
            calzado: response[i].services.calzado,
            instructions: response[i].instructions,
            commentUser: response[i].aditionalComment,
            status: response[i].status,
            total: response[i].price,
            building: response[i].building,
            payed: response[i].payed,
            userId: response[i]._userId,
            creation_date: getDate(response[i].createdAt),
            creation_hour: getHour(response[i].createdAt),
            deliveryDate: getDate(response[i].deliveryDate)
          })  
        }
        console.log("orders", tmp)
        setOrders(tmp);
        getOrderDetail(response);
      }
      
      getUser();
      getOrder()
      getBuildings();
    }, [])

    return (
      <Dashboard>
        <Grid container component="main" className={classes.root}>
          <main className={classes.content}>
            <Container className={classes.container}>
                {!loading ?<Grid container direction="row">
                    <Grid item xs={6} md={3}>
                        <Button variant="outlined">
                          <CSVLink
                            data={user}
                            filename="user.csv"
                            separator={","}
                          >
                            Usuarios
                          </CSVLink>
                        </Button>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Button variant="outlined" color="primary">
                          <CSVLink
                            data={orders}
                            filename="orders.csv"
                            separator={","}
                          >
                            Ordenes
                          </CSVLink>
                        </Button>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Button variant="outlined" color="secondary">
                          <CSVLink
                            data={ordersDetail}
                            filename="ordersDetail.csv"
                            separator={","}
                          >
                            Detalle de ordenes
                          </CSVLink>
                        </Button>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Button variant="outlined" color="primary" href="#outlined-buttons">
                          <CSVLink
                            data={buildings}
                            filename="building.csv"
                            separator={","}
                          >
                            Edificios
                          </CSVLink>
                        </Button>
                    </Grid>
                </Grid>: <Grid container justify="center"><CircularProgress /></Grid>}
            </Container>
          </main>
        </Grid>
      </Dashboard>
      );
    }